<template>
  <div class="container" data-test-metadata-filter>
    <div class="header">
      <span v-text="$t('entityFilters.fields.metadata')"></span>
      <div role="button" class="primary-text" @click="isAdding = true">
        <v-icon size="0.75rem">fi-br-plus</v-icon>
        <span v-text="$t('entityFilters.fields.addMetadata')"></span>
      </div>
    </div>

    <template v-if="isAdding">
      <FindSearch
        :inputLabel="$t('entityFilters.fields.labelMetadata')"
        :attach="true"
        :items="fieldsOptions"
        :selectedItems="fieldsSelected"
        @update:item="addMetadata($event)"
        @focus:input="$emit('focus:input')"
        @search:item="$emit('search:item', $event)"
      ></FindSearch>
    </template>

    <div v-if="fieldsSelected" class="d-flex flex-column metadata-container">
      <template v-for="(metadata, index) in fieldsSelected || []">
        <v-col
          :key="metadata.id"
          class="d-flex flex-row align-center metadata-item"
        >
          <MetadataField
            :item="metadata"
            :currentValue="{ ...form[index] }"
            :canEmpty="true"
            :showEmptyItem="true"
            :showInactive="showInactive"
            @update:item="changeMetadata(index, $event)"
          ></MetadataField>

          <div role="button" class="remove" @click="removeMetadata(index)">
            <v-icon size="0.75rem">fi-rr-trash</v-icon>
          </div>
        </v-col>
      </template>
    </div>
  </div>
</template>

<script>
import MetadataField from '@/views/Performance/Views/Parts/MetadataField/MetadataField.vue'
import FindSearch from '../FindSearch/FindSearch.vue'

export default {
  name: 'MetadataSelector',
  components: {
    MetadataField,
    FindSearch,
  },
  props: {
    fieldsOptions: {
      type: Array,
      default: () => [],
    },
    showInactive: {
      type: [Boolean, null],
      default: null,
    },
    preSelectedFields: Array,
    preValue: Array,
  },
  data() {
    return {
      isAdding: false,
      fieldsSelected: [],
      form: [],
    }
  },
  methods: {
    handleUpdateItem() {
      this.$emit('update:item', this.form)
    },

    handleUpdateFields() {
      this.$emit('update:fields', this.fieldsSelected)
    },

    changeMetadata(metaIndex, event) {
      if (this.form.length - metaIndex < 1) {
        for (let i = this.form.length; i < metaIndex; i++) {
          if (!this.form[i]) this.form[i] = null
        }
      }

      if (this.form[metaIndex] === event) {
        return
      }

      this.form.splice(metaIndex, 1, event)

      this.handleUpdateItem()
    },

    addMetadata(event) {
      this.isAdding = false
      this.fieldsSelected.push(event)

      this.handleUpdateFields()
    },
    removeMetadata(metaIndex) {
      this.fieldsSelected.splice(metaIndex, 1)
      this.form.splice(metaIndex, 1)

      this.handleUpdateItem()
      this.handleUpdateFields()
    },

    cleanFilter() {
      for (let i = 0; i < this.form.length; i++) {
        this.form.splice(i, 1, null)
      }

      this.handleUpdateItem()
    },
  },

  mounted() {
    if (this.preSelectedFields) {
      this.fieldsSelected = this.preSelectedFields
    }

    if (this.preValue) {
      this.form = this.preValue
    }
  },

  watch: {
    preSelectedFields(val) {
      if (Array.isArray(val)) {
        this.fieldsSelected = val
        return
      }

      this.fieldsSelected.length = 0
    },
    preValue(val) {
      if (val) {
        this.form = val
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
