export const engagement = 'engagement'
export const engagement_climate = 'engagement.climate'
export const engagement_culture = 'engagement.culture'
export const engagement_participations = 'engagement.participations'
export const engagement_groups_comparation = 'engagement.groups-comparation'
export const engagement_benchmark = 'engagement.benchmark'
export const engagement_opinions = 'engagement.opinions'
export const engagement_opinions_rating = 'engagement.opinions.rating'
export const engagement_action_plans = 'engagement.action-plans'

export const engagement_survey = 'engagement.survey'
