const metadata_values_keys = ['text', 'date', 'people', 'values']
const metadata_special_filter = {
  'field:empty': { 'field:empty': true },
}
const metadata_special_filter_keys = Object.keys(metadata_special_filter)

export function handleMetadataWithEmptyItem(item) {
  const transformArrayOfItems = items => {
    return items
      .map(item => {
        if (!item) {
          return item
        }

        const value = item.id || item.value
        if (metadata_special_filter_keys.includes(value)) {
          return metadata_special_filter[value]
        }

        return { id: value }
      })
      .filter(Boolean)
  }

  let data = { ...item }
  Object.keys(item).some(key => {
    if (!metadata_values_keys.includes(key)) {
      return false
    }

    if (
      ['text', 'date'].includes(key) &&
      metadata_special_filter_keys.includes(item[key])
    ) {
      data[key] = metadata_special_filter[item[key]]
      return true
    }

    if (Array.isArray(item[key])) {
      data[key] = transformArrayOfItems(item[key])
      return true
    }
  })

  return data
}

export function handleSendValueMetadataFilter(filters, key) {
  const isValid = filters[key] && [...filters[key].filter(Boolean)].length > 0
  if (!isValid) {
    filters[key] = null
    return
  }

  if (Array.isArray(filters[key])) {
    filters[key] = filters[key]
      .map(item => {
        if (!item) {
          return item
        }

        return handleMetadataWithEmptyItem(item)
      })
      .filter(Boolean)
  }
}
