import { onResponseValidateUnauthorized } from '@/helpers/axios/on-response-validate-unauthorized'

/**
 * @param {import('axios').AxiosResponse} response
 */
export function onFulfilledResponse(response) {
  return response
}

/**
 * @param {Error} error
 */
export function onRejectedResponse(error) {
  onResponseValidateUnauthorized(error)

  return Promise.reject(error)
}
