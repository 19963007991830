<template>
  <div data-test-sidebar-entity-filter>
    <LateralMenuItem
      :title="$t('entityFilters.menuItem.title')"
      :is-mini="isMini"
    >
      <template #title-icon="{ size }">
        <v-icon :size="size">fi-sr-filter</v-icon>
      </template>

      <template #header-action>
        <v-btn
          data-test-clean-entity-filter
          class="pa-1"
          plain
          text
          small
          @click="cleanEntityFilter()"
        >
          <span>{{ $t('entityFilters.menuItem.actions.clean') }}</span>
        </v-btn>
      </template>

      <ComposeFilters
        ref="ComposeFilters"
        class="entity-filter"
        :form-name="filterName"
        :selectors="_selectors"
        :active-cycle-i-ds="activeCycleIDs"
        :storage-preferences="_storagePreferences"
        @compose:values="setFiltersValue"
        @api:itens="handleApiItens"
        @mounted="ComposeFiltersMounted"
      >
        <template
          #metadata="{
            field,
            fieldsValues,
            fieldsOptions,
            getOptions,
            getOptionsSearch,
            setValue,
            getComposeFormPreferences,
            setComposeFormPreferences,
          }"
        >
          <MetadataSelector
            ref="MetadataFilters"
            :fields-options="field.options || fieldsOptions[field.id]"
            :pre-selected-fields="
              getComposeFormPreferences({ name: '-' + field.id }, filterName)
            "
            :pre-value="fieldsValues[field.id] || []"
            :show-inactive="true"
            @focus:input="getOptions(field.id)"
            @search:item="getOptionsSearch(field.id, $event)"
            @update:item="setValue($event, field.id, field.type)"
            @update:fields="
              setComposeFormPreferences(
                false,
                {
                  name: '-' + field.id,
                  data: $event,
                },
                filterName
              )
            "
          ></MetadataSelector>
        </template>
      </ComposeFilters>
    </LateralMenuItem>
  </div>
</template>

<script>
import ComposeFilters from '../ComposeFilters/ComposeFilters.vue'
import MetadataSelector from './Partials/MetadataSelector/MetadataSelector.vue'

import { handleFilters } from '@/helpers/filters/okrs'
import { handleSendValueMetadataFilter } from '@/helpers/filters/metadata'

export default {
  name: 'EntityFilters',

  components: {
    MetadataSelector,
    ComposeFilters,
  },
  props: {
    filterName: {
      type: String,
      required: true,
    },
    isMini: Boolean,

    activeCycleIDs: {
      type: Array,
      default: () => [],
    },

    /**
     * @type {Object}
     * @property {filterField} fields
     */
    selectors: {
      type: Object,
      default: () => ({
        fields: [
          /**
           * @type {Object} filterField
           * @property {String} id
           * @property {String} label - pode ser uma chave de internacionalização correspondente ao "entityFilters.fields"; fallback para id, fallback para proprio texto.
           * @property {Array} options
           */
          {
            id: 'directGroupOnly',
            options: [
              {
                id: 'all',
                msg: 'entityFilters.fields.directGroupOnly.all',
              },
              {
                id: 'only',
                msg: 'entityFilters.fields.directGroupOnly.only',
              },
            ],
            label: 'exibition',
            default: 'all',
          },
          { id: 'entityIDs', options: null },
          { id: 'workflowStatusIDs', label: 'workflowStatus', options: null },
          { id: 'entityType', options: null },
          { id: 'entityClass', options: null },
          { id: 'ownerIDs', label: 'owners', options: null },
          { id: 'peopleGroupIDs', label: 'groups', options: null },
          { id: 'personIDs', label: 'peoples', options: null },
          { id: 'accessProfileIDs', label: 'ownersRoles', options: null },
          { id: 'tagIDs', label: 'tags', options: null },
          { id: 'metadata', options: null },
        ],
      }),
    },
  },

  data() {
    return {
      isFirstReload: false,
      form: {
        filter: {},
      },
    }
  },

  computed: {
    _selectors() {
      return this.handleSelectors(this.selectors)
    },
    _storagePreferences() {
      return true
    },
  },

  methods: {
    handleSelectors({ fields }) {
      const hidratedFields = handleFilters(fields)

      return {
        fields: hidratedFields,
      }
    },

    setFiltersValue(values) {
      this.$set(this.form, 'filter', values)
      this.sendFilterValues(values)
    },

    sendFilterValues(values) {
      let filters = structuredClone(values)

      const ArrayOrNull = (items, key) => {
        if (!items) return null
        return [...items].length > 0 ? items.map(item => item[key]) : null
      }

      Object.keys(filters).forEach(key => {
        if (
          [
            'entityIDs',
            'entityType',
            'entityClass',
            'invitationStatus',
            'ownerIDs',
            'peopleGroupIDs',
            'personIDs',
            'accessProfileIDs',
            'workflowStatusIDs',
            'status',
            'tagIDs',
          ].includes(key)
        ) {
          filters[key] = ArrayOrNull(filters[key], 'id')
        }

        if (['metadata'].includes(key)) {
          handleSendValueMetadataFilter(filters, key)
        }

        if (['peopleGroupIDs'].includes(key)) {
          filters[key] = Array.isArray(filters[key])
            ? filters[key].filter(Boolean)
            : null
        }

        if (['entityType', 'entityClass', 'invitationStatus'].includes(key)) {
          filters[key] = Array.isArray(filters[key]) ? filters[key]?.[0] : null
        }
      })

      this.$root.$emit('filter:entity:changes', filters)
    },

    cleanEntityFilter() {
      if (this.$refs.ComposeFilters) {
        this.$refs.ComposeFilters.cleanEntityFilter()

        this.cleanMetadataFilter()
      }
    },

    cleanMetadataFilter() {
      const metaRef = this.$refs.MetadataFilters
      if (!metaRef) {
        return
      }

      if (Array.isArray(metaRef)) {
        metaRef.forEach(ref => {
          ref.cleanFilter()
        })
        return
      }

      metaRef.cleanFilter()
    },

    defineFieldsAsValue(value, fieldID) {
      if (this.$refs.ComposeFilters) {
        this.$refs.ComposeFilters.defineFieldsAsValue(
          {
            [fieldID]: value,
          },
          false
        )
        this.$refs.ComposeFilters.setComposeFormPreferences()
      }
    },

    handleApiItens({ fieldID, hidratedItens, currentValue, isDirt }) {
      if (!this.$refs.ComposeFilters) {
        return
      }

      const HidrateOnFirstLoad = !Array.isArray(currentValue) && !isDirt
      const HasItensToHidrate =
        Array.isArray(hidratedItens) && hidratedItens.length

      if (fieldID === 'status') {
        this.$refs.ComposeFilters.selectorsFieldsOptions[
          fieldID
        ][0].items.unshift({
          color: null,
          id: null,
          label: this.$t('InitiativesTable.progress-status.undefined'),
          value: null,
        })
      }

      if (
        this.filterName === 'coresponsibility' &&
        fieldID === 'accessProfileIDs' &&
        HidrateOnFirstLoad &&
        HasItensToHidrate
      ) {
        let selectOne = []
        selectOne.push(
          ...(Array.isArray(hidratedItens)
            ? hidratedItens.filter(Boolean)
            : [hidratedItens])
        )
        selectOne.length = 1

        this.defineFieldsAsValue(selectOne, fieldID)
      }

      if (
        this.filterName === 'coresponsibility' &&
        fieldID === 'workflowStatusIDs' &&
        (isDirt || (this.isFirstReload && HasItensToHidrate))
      ) {
        this.defineFieldsAsValue([hidratedItens[0], hidratedItens[3]], fieldID)
        this.isFirstReload = false
      }
    },
    ComposeFiltersMounted() {
      this.isFirstReload = true
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
