import { projectAuth } from '@/firebase/config'

export function onResponseValidateUnauthorized(error) {
  if (!projectAuth?.currentUser) {
    return
  }

  const ForceLogoutStatus = [401]
  if (ForceLogoutStatus.includes(error?.response?.status)) {
    projectAuth.signOut().then(() => {
      window.location = '/management/login'
    })
  }
}
