import api from '@/services/Http'
import { extractSignal, constructParams } from '@/helpers/api'

export async function getMetadata(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    ['limit', 'offset', 'entityType', 'entityClass', 'label', 'cycleIDs'],
    data
  )

  return (await api()).get(`/performance/okrs/metadata`, {
    params: params,
    ...signal,
  })
}

export async function getMetadataGroups(data) {
  const signal = extractSignal(data)
  const params = constructParams(
    ['limit', 'offset', 'entityType', 'entityClass', 'label'],
    data
  )

  return (await api()).get('/performance/okrs/metadata-groups', {
    params: params,
    ...signal,
  })
}
