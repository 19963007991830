<template>
  <v-app id="app">
    <LateralMenu v-if="_isSignedIn && _showLateralMenu"></LateralMenu>

    <nav
      data-test-nav-bar
      class="nav-menu"
      aria-label="TeamCulture Site Navigation"
    >
      <NavBar
        v-if="_isSignedIn && !_hideSiteNavigation"
        :user="_user"
        :user-links="_navBarLinks.userLinks"
        :menu-links="_navBarLinks.menuLinks"
        :has-lateral-action="true"
      >
        <template #footer="{ isMiniVariant }">
          <NotificationMenu
            :is-mini-variant="isMiniVariant"
            @show-alert="$refs.AlertBar.displayAlert($event)"
          ></NotificationMenu>

          <ToLegacy
            v-if="false"
            :is-mini-variant="isMiniVariant"
            :legacy-u-r-l="_legacyURL"
          ></ToLegacy>

          <LanguageMenu
            v-if="false"
            :current-language="_currentLanguage"
            :is-mini-variant="isMiniVariant"
            @selected-language="handleSyncLanguage"
          ></LanguageMenu>

          <HelpAxel
            v-if="false"
            :is-mini-variant="isMiniVariant"
            :go-help-axel="goHelpAxel"
          ></HelpAxel>
        </template>
      </NavBar>
    </nav>

    <v-main class="page">
      <v-container fluid class="temp-container">
        <router-view
          ref="PageView"
          @force-logout="handleSignOut"
          @update-permissions="updatePermissions()"
        ></router-view>
      </v-container>

      <AlertBar ref="AlertBar"></AlertBar>
    </v-main>
  </v-app>
</template>

<script>
import { projectAuth } from '@/firebase/config'

import _EventBus from '@/plugins/eventBus'

import { abilityHelper } from '@/helpers/ability'
import {
  calcLanguageToApi,
  calcLanguageToLocale,
  saveLanguage,
} from '@/helpers/locale'
import { genSiteNavigationItens } from '@/helpers/menu-itens'

import { mapGetters, mapState } from 'vuex'
import { debounce } from 'lodash'

import NotificationMenu from '@/views/Notification/Views/NotificationMenu/NotificationMenu.vue'

import LateralMenu from '@/views/Performance/Views/LateralMenu/LateralMenu.vue'

const MainPageRouterName = 'PerformancePage'
import * as _canKey from '@/helpers/ability/permissions'

export default {
  name: 'PerformanceApp',
  components: {
    LateralMenu,
    NotificationMenu,
  },

  provide() {
    return {
      handleChangeLanguage: this.handleChangeLanguage,
      handleSyncLanguage: this.handleSyncLanguage,
      handleSignOut: this.handleSignOut,
    }
  },

  data() {
    return {
      callHandleSignIn: debounce(async function (payload) {
        await this.handleSignIn(payload)
      }, 250),
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: this._currentLanguage,
      },
      title: this._currentTitle,
    }
  },

  computed: {
    ...mapGetters({
      _isSignedIn: 'isSignedIn',
      _user: 'currentUser/profile',
    }),

    ...mapState(['activeCycles']),

    _showLateralMenu() {
      const baseRouteName = this.$route.matched[0]
        ? this.$route.matched[0].name
        : this.$route.name

      return ['PerformancePage', 'Notification'].includes(baseRouteName)
    },

    _legacyURL() {
      return new URL(process.env.VUE_APP_ENGAGEMENT_LEGACY_LINK).toString()
    },

    _currentTitle() {
      const title = this.$route.matched.findLast(record => record?.meta?.title)

      if (!title) {
        return undefined
      }

      return `${this.$t(title.meta.title)} - TeamCulture`
    },
    _currentLanguage() {
      return this.$t('locale')
    },

    _hideSiteNavigation() {
      const contexts = ['Login', 'NotFound']

      const baseRouteName = this.$route.matched[0]
        ? this.$route.matched[0].name
        : this.$route.name

      return contexts.includes(baseRouteName)
    },

    _navBarLinks() {
      const { userLinks, menuLinks } = genSiteNavigationItens(
        this.handleSignOut
      )

      return this.calcNavBarLinksByPermissions(userLinks, menuLinks)
    },

    _canAppHideLegacyButton() {
      return this.$can('access', 'app.hide.legacy-button')
    },
  },

  created() {
    _EventBus.$on('bus:alert', this.displayAlert)

    this.handleChangeLanguage(false)
  },
  beforeDestroy() {
    _EventBus.$off('bus:alert')
  },

  mounted() {
    this.onAuthStateChangedByLogin()
  },

  methods: {
    displayAlert(payload) {
      if (!payload) return

      this.$refs.AlertBar.displayAlert(payload)
    },

    goHelpAxel() {
      const $sleek = window?.$sleek

      if ($sleek) {
        $sleek.open()
        return
      }

      window.open(
        this.$t('ui-components.siteNavigation.helpInfoLink'),
        '_blank'
      )
    },

    async updatePermissions() {
      const payload = {
        refresh: true,
        params: {
          cycleIDs: this.activeCycles.map(el => el.id),
        },
      }

      this.$store
        .dispatch('currentUser/getAccountMe', payload)
        .then(data => {
          abilityHelper(data.permissions)
        })
        .catch(() => {
          // User is signed out.
          this.handleSignOut().then(() => {
            this.handleLoginPageMethods('customErrorMessage')
          })
        })
    },

    getViewLinkPermissioned(link) {
      if (Array.isArray(link?.permissioned)) {
        return link.permissioned.some(perm => this.$can('access', perm))
      }

      return !link?.permissioned || this.$can('access', link.permissioned)
    },

    calcNavBarLinksByPermissions(userLinks, menuLinks) {
      const menuLinksByPermissions = menuLinks
        .map(link => {
          let showLink = this.getViewLinkPermissioned(link)

          if (link?.newLayout) {
            const useNewLayout =
              !link?.newLayout?.permissioned ||
              this.$can('access', link.newLayout.permissioned)

            if (useNewLayout) {
              return {
                ...link,
                ...link?.newLayout,
              }
            }
          }

          if (!showLink) {
            return null
          }

          return link
        })
        .filter(Boolean)

      return {
        userLinks,
        menuLinks: menuLinksByPermissions,
      }
    },

    onAuthStateChangedByLogin() {
      // wait for login
      this.$store.dispatch('signedInLogout')

      projectAuth.onAuthStateChanged(this.onAuthStateChangedNextOrObserver)
    },

    /**
     * @param {import('@firebase/auth').User} User
     */
    async onAuthStateChangedNextOrObserver(User) {
      if (!User) {
        this.handleLogout()

        this.callHandleSignIn.cancel()
        return
      }

      await this.$nextTick()

      this.callHandleSignIn({ refresh: true })
    },

    handleLogout() {
      this.$nextTick(() => {
        this.handleLoginPageMethods('setLoading', false)
      })

      this.$store.dispatch('signedInLogout')
      this.$store.dispatch('currentUser/logoutAccount')

      localStorage.removeItem('teamculture:token')
    },

    async handleSignOut() {
      return new Promise(resolve => {
        projectAuth.signOut().then(() => {
          this.handleLogout()

          if (!window.location.href.includes('login')) {
            this.$router.push({ name: 'Login' })
          }

          resolve()
        })
      })
    },

    async handleSignIn(payload) {
      this.handleLoginPageMethods('setLoading', true)

      /* eslint-disable no-unused-vars */
      return this.$store
        .dispatch('currentUser/getAccountMe', payload)
        .then(data => {
          const lang = calcLanguageToLocale(data.language)
          this.handleChangeLanguage(lang)

          //abilityHelper([]) // @TODO Em Branco
          abilityHelper(data.permissions)

          this.$store.dispatch('signedInLogin')

          this.handleSleek(data)

          this.defineRouteGuardWatch()

          this.handleFromLoginToPage()
        })
        .catch(_error => {
          this.handleLoginPageMethods('setLoading', false)
          this.handleLoginPageMethods('customErrorMessage')

          // User is signed out.
          this.handleSignOut()
        })
      /** eslint-enable no-unused-vars */
    },

    handleFromLoginToPage() {
      const from = sessionStorage.getItem('interface-recovery--redirectPath')

      if (from) {
        const { to, toQuery } = JSON.parse(from)
        sessionStorage.removeItem('interface-recovery--redirectPath')
        this.$router.push({ ...to, query: toQuery })

        return
      }

      if (!window.location.href.includes('login')) {
        return
      }

      this.$router.push({ name: 'Main' })
    },

    handleLoginPageMethods(methodName, ...payload) {
      this.$nextTick(() => {
        if (!window.location.href.includes('login')) {
          return
        }

        const method = this.$refs.PageView?.[methodName]
        if (typeof method !== 'function') {
          return
        }

        method(...payload)
      })
    },

    handleSleek(user) {
      const $sleek = window?.$sleek

      if (!$sleek) {
        return
      }

      const {
        email,
        name,
        planID,
        isAdmin = false,
        accountName,
        accountPeople,
      } = user

      $sleek.setUser({
        id: email,
        name,
        mail: email,
        token: email,
        weight: isAdmin ? 10 : 1,
      })

      $sleek.addMetadata({
        account_name: accountName,
        account_people: accountPeople,
        account_planID: planID,
        person_admin: isAdmin,
      })

      $sleek.hideButton()
    },

    defineRouteGuardWatch() {
      return this.$watch('$route.matched', this.handleRouteGuard, {
        immediate: true,
      })
    },

    async handleRouteGuard(matched) {
      const canPagePermissions = this.calcRoutePermissions(matched)
      const shouldGuardRoute = Object.keys(canPagePermissions).length
      const hasPermission = Object.values(canPagePermissions).every(
        permission => permission === true
      )

      if (shouldGuardRoute && !hasPermission) {
        this.goNextRoutePossible(MainPageRouterName)
      }

      return shouldGuardRoute && !hasPermission
    },

    /**
     * @param {Array} matched
     */
    calcRoutePermissions(matched) {
      const requiresPermissions = Array.from(
        new Set(matched.flatMap(record => record?.meta?.requiresPermission))
      ).filter(Boolean)

      const canPagePermissions = requiresPermissions.reduce(
        (obj, permission) => {
          const canPermission = this.$can('access', permission)
          return { ...obj, [permission]: canPermission }
        },
        {}
      )

      return canPagePermissions
    },

    /**
     * @param {String} RouteName
     */
    goNextRoutePossible(RouteName) {
      const PageRouter = this.$router.options.routes.find(
        r => r.name === RouteName
      )

      const canAccess = routePermission => {
        if (typeof routePermission !== 'boolean') {
          return Object.values(routePermission).some(childPermission =>
            canAccess(childPermission)
          )
        }

        return routePermission
      }

      const getRoutePermissions = Route =>
        Route?.children?.reduce((obj, page) => {
          if (page.path.includes(':')) {
            return { ...obj }
          }

          const matched = [Route, page]
          let hasPermission = Object.values(
            this.calcRoutePermissions(matched)
          ).every(permission => permission === true)

          if (page?.children) {
            const children = getRoutePermissions(page)

            if (Object.keys(children).length) {
              const hasPermissionChildren = Object.values(children).some(
                permission => canAccess(permission)
              )

              hasPermission = hasPermissionChildren ? children : false
            }
          }

          return { ...obj, [page.name]: hasPermission }
        }, {}) || {}
      const PageChilds = getRoutePermissions(PageRouter)

      let nextRoutePossible
      const getNextRoutePossible = Routes =>
        Object.keys(Routes).some(permission => {
          if (typeof Routes[permission] !== 'boolean') {
            return getNextRoutePossible(Routes[permission])
          }

          if (Routes[permission]) {
            nextRoutePossible = permission
          }

          return Routes[permission]
        })
      getNextRoutePossible(PageChilds)

      if (!nextRoutePossible && this.$can('access', _canKey.profile)) {
        return window.location.assign('/profile')
      }

      if (!nextRoutePossible) {
        return this.handleSignOut().then(() => {
          this.handleLoginPageMethods('customErrorMessage', {
            text: 'pageLogin.errorPermission.text',
          })
        })
      }

      setTimeout(() => {
        if (this.$route.matched.some(r => r.name === nextRoutePossible)) {
          return
        }

        this.$router.push({ name: nextRoutePossible })
      }, 0)
    },

    /**
     * @param {String} lang
     */
    async handleSyncLanguage(lang) {
      const language = calcLanguageToApi(lang)

      return this.$store
        .dispatch('currentUser/putAccountMe', { language })
        .then(() => {
          this.handleChangeLanguage(lang)
        })
    },

    /**
     * @param {String} lang
     */
    async handleChangeLanguage(lang) {
      const { language, reloadByNotEqSession } = saveLanguage(lang)
      this.$root.$i18n.locale = language

      if (!reloadByNotEqSession) {
        return
      }

      const { l: urlLang = false } = this.$route.query
      const preventUrlNotEqSession = calcLanguageToLocale(urlLang) !== language
      if (urlLang && preventUrlNotEqSession) {
        return
      }

      window.location.reload()
    },
  },
}
</script>

<style lang="scss">
@import '@/global.scss';
@import '@/styles/scss/_colors.scss';

// TEMPORÁRIO
.temp-container {
  height: 100%;
  padding: inherit !important;
}
#performance-page {
  height: 100%;
}

.user-options-menu {
  [data-test-menu-user="'settings'"],
  .divider-neutral {
    display: none;
  }
}
</style>
