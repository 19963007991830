// https://firebase.google.com/docs/reference/js/auth.md#auth_package
import { getAnalytics, logEvent } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'

import {
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  RecaptchaVerifier,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  getIdTokenResult,
  linkWithCredential,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_APIKEY,
  authDomain: process.env.VUE_APP_FB_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FB_PROJECTID,
  storageBucket: process.env.VUE_APP_FB_STORAGE,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING,
  appId: process.env.VUE_APP_FB_APPID,
  measurementId: process.env.VUE_APP_FB_MEASUREMENT,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app)
// EXEMPLE: logEvent(analytics, 'notification_received')

const projectAuth = getAuth()
projectAuth.useDeviceLanguage()

export {
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  RecaptchaVerifier,
  analytics,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getIdTokenResult,
  linkWithCredential,
  logEvent,
  projectAuth,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
}
