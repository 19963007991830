import i18n from '@/plugins/i18n'

function constructFilterItemSelector(filter) {
  return {
    type: 'ItemSelector',

    submit: {
      type: 'ArrayOf',
      identifier: 'id',
    },

    ItemSelector: {
      localSearch: filter.localSearch,

      menuConfig: {
        align: 'right',
        attach: false,
        showTabs: false,
      },

      inputConfig: {
        closeOnSelect: !filter.multiple,

        showElements: !['seen'].includes(filter.id),

        clearable: true,
        itemsToPresent: 2,
      },

      type: filter.type,
      multiple: filter.multiple,
    },
  }
}

function constructFilterCustomDatePicker(filter) {
  return {
    type: 'CustomDatePicker',

    CustomDatePicker: {
      dateTime: filter.dateTime,
      multiple: filter.multiple,
      range: filter.range,
    },
  }
}

function getConstructor(filterID) {
  const FiltesTypesByID = {
    dateRange: 'CustomDatePicker',
    seen: 'ItemSelector',
  }
  const type = FiltesTypesByID[filterID]

  const constructorsByType = {
    ItemSelector: constructFilterItemSelector,
    CustomDatePicker: constructFilterCustomDatePicker,
  }
  const construct = constructorsByType[type]

  if (!construct) {
    return null
  }

  return construct
}

function constructFilterApi(filterID) {
  const payloadByID = {}

  const payload = payloadByID[filterID] || null

  return {
    api: payload,
  }
}

function constructFilterOptions(filterID, label = null, type = null) {
  const payloadByID = {
    seen: [
      {
        value: filterID,
        label,
        type,
        items: [
          {
            label: i18n.t('notificationCenterFilter.options.seen.read'),
            id: 'read',
          },
          {
            label: i18n.t('notificationCenterFilter.options.seen.unread'),
            id: 'unread',
          },
        ],
      },
    ],
  }

  const payload = payloadByID[filterID] || null
  return {
    options: payload,
  }
}

function constructFilterDefault(filterID) {
  const payloadByID = {}

  const payload = payloadByID[filterID] || null
  return {
    default: payload,
  }
}

function constructFilterPreference(filterID, label) {
  const constructLabel = label => {
    if (label) {
      return i18n.t(`notificationCenterFilter.fields.${label}`)
    }

    return i18n.t(`notificationCenterFilter.fields.${filterID}`)
  }

  const payloadByID = {
    dateRange: {
      multiple: false,
      dateTime: false,
      range: true,
    },
    seen: {
      multiple: false,
      localSearch: true,
      type: 'listview',
    },
  }

  const payload = payloadByID[filterID] || {}
  return {
    ...payload,
    id: filterID,
    _label: constructLabel(label),
  }
}

function calcFilter(filter) {
  const constructor = getConstructor(filter.id)
  if (!constructor) {
    return null
  }

  const constructorPayload = constructFilterPreference(filter.id, filter?.label)

  const apiPayload = constructFilterApi(filter.id)
  const optionsPayload = constructFilterOptions(
    filter.id,
    constructorPayload?._label,
    constructorPayload?.type
  )
  const defaultPayload = constructFilterDefault(filter.id)
  const payload = constructor(constructorPayload)

  return {
    id: filter.id,
    _label: constructorPayload._label,
    ...payload,
    ...optionsPayload,
    ...apiPayload,
    ...defaultPayload,
    eagerApi: !!filter?.eagerApi,
  }
}

export function handleFilters(filters) {
  return filters.map(filter => calcFilter(filter)).filter(Boolean)
}
