<template>
  <div id="field" :class="item.type">
    <!-- text type -->
    <CustomInput
      ref="input"
      v-if="item.type === 'text'"
      :label="item.label"
      :currentValue="handleEmptyInputForm(form)"
      :readonly="_readOnlyPermission"
      :mandatory="rules.length > 0"
      :viewMode="subtleMode"
      :suffixTitle="$t('entityFilters.fields.emptyFilter')"
      :suffixIcon="'fi-rr-test'"
      :suffixAction="true"
      @handleInput="form = $event"
      @click:suffix="handleEmptyFilter"
    ></CustomInput>

    <!-- select type -->
    <SingleSelection
      v-if="item.type === 'select' && !item.multi"
      :mandatory="false"
      :label="item.label"
      :placeholder="item.label"
      :editable="!_readOnlyPermission"
      :rules="rules"
      :items="_selectorsFieldsOptions"
      :selectedItem="form || ''"
      :readonly="subtleMode"
      @focus:input="getOptions()"
      @selectOption="form = $event.value"
    />

    <!-- select multi type -->
    <ItemSelector
      ref="input"
      v-if="item.type === 'select' && item.multi"
      :multiple="true"
      :showElements="true"
      :watchCurrent="true"
      :fillWidth="true"
      :localSearch="true"
      :readonly="_readOnlyPermission"
      :inputConfig="{
        label: item.label,
        rules: rules,
        subtleMode: subtleMode,
        showAvatar: false,
        clearable: true,
        itemsToPresent: 2,
      }"
      :menuConfig="{
        showTabs: false,
      }"
      :menuOptions="_selectorsFieldsOptions"
      :currentValue="{
        data: form || [],
        origin: item.id,
      }"
      @focus:input="getOptions()"
      @update:item="handleItemSelectorInput($event)"
    />

    <!-- people type -->
    <ItemSelector
      ref="input"
      v-if="['people'].includes(item.type)"
      :inputLabel="item.label"
      :multiple="item.multi"
      :showElements="true"
      :watchCurrent="true"
      :fillWidth="true"
      :localSearch="false"
      :readonly="_readOnlyPermission"
      :inputConfig="{
        label: item.label,
        rules: rules,
        subtleMode: subtleMode,
        clearable: true,
        itemsToPresent: 2,
      }"
      :menuConfig="{
        showTabs: false,
      }"
      :menuOptions="_selectorsFieldsOptions"
      :currentValue="{
        data: form || [],
        origin: item.id,
      }"
      :loading="
        (selectorsFieldsLoading && selectorsFieldsLoading['people']) || false
      "
      @update:item="handleItemSelectorInput($event)"
      @focus:input="getOptions()"
      @search:item="getOptionsSearch($event)"
    />

    <!-- date type -->
    <CustomDatePicker
      ref="input"
      v-if="item.type === 'date'"
      :mandatory="false"
      :label="item.label"
      :closeOnClick="true"
      :subtleMode="subtleMode"
      :menuProps="{
        attach: false,
        'z-index': 9999,
        'content-class': 'mt-1',
      }"
      :textInputProps="{
        'hide-details': 'auto',
      }"
      :rules="rules"
      :readonly="_readOnlyPermission"
      :dateSelected="handleEmptyInputForm(form)"
      :suffixTitle="$t('entityFilters.fields.emptyFilter')"
      :suffixIcon="'fi-rr-test'"
      :suffixAction="true"
      @click:suffix="handleEmptyFilter"
      @input="form = $event"
    ></CustomDatePicker>
  </div>
</template>

<script>
import { getPeople } from '@/services/people'

export default {
  name: 'MetadataField',
  props: {
    showInactive: {
      type: [Boolean, null],
      default: null,
    },
    timeDebounce: {
      type: Number,
      default: 800,
    },
    item: {
      type: Object,
      required: true,
    },
    currentValue: {
      type: [String, Array, Object],
    },
    options: {
      type: Object,
    },
    watchCurrent: {
      type: Boolean,
      default: true,
    },
    subtleMode: {
      type: Boolean,
      default: false,
    },
    canEmpty: {
      type: Boolean,
      default: false,
    },
    showEmptyItem: {
      type: Boolean,
      default: false,
    },
    hasPermission: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: null,

      debounceTimeOut: null,
      dirtSearch: false,

      selectorsFieldsOptions: [],
      selectorsFieldsLoading: {
        people: false,
      },
    }
  },
  computed: {
    _readOnlyPermission() {
      if (this.hasPermission) {
        return !this.hasPermission
      }

      if ('readonly' in this.item) {
        return this.item.readonly
      }

      return false
    },
    _metadataValid() {
      if (this._readOnlyPermission) {
        return true
      }

      if (this.canEmpty) {
        return ![null, undefined].includes(this.form)
      }

      if (!this.item.multi) {
        return !!this.form
      }

      return this.form?.length > 0
    },
    _selectorsFieldsOptions() {
      return this.handleSelectorsFieldsOptions(this.selectorsFieldsOptions)
    },
  },
  methods: {
    handleEmptyFilter(isEmpty) {
      if (!this.canEmpty) {
        return
      }

      if (isEmpty) {
        this.form = 'field:empty'
        return
      }

      this.form = this.handleEmptyInputForm(this.form || '')
    },

    handleEmptyInputForm(value) {
      if (!value) return value

      const displayValue = JSON.parse(JSON.stringify(value))
      return displayValue.replace('field:empty', '')
    },

    handleSelectorsFieldsOptions(options) {
      const item = this.item

      if (item.multi || item.type === 'people') {
        const label =
          item.type === 'people'
            ? this.$t('entityFilters.options.peoples')
            : item.label

        return [
          {
            value: item.id,
            label: label,
            type: 'listview',
            items: this.handleItemsWithEmptyFilter(options || []),
          },
        ]
      }

      return this.handleItemsWithEmptyFilter(
        options || [],
        this.handleSingleSelectionDataToItems
      )
    },

    handleItemsWithEmptyFilter(items, callbackFn = items => items) {
      if (!this.showEmptyItem) return callbackFn(items)

      const emptyFilterItem = {
        id: 'field:empty',
        value: this.$t('entityFilters.options.empty'),
        label: this.$t('entityFilters.options.empty'),
      }

      return callbackFn([emptyFilterItem, ...items])
    },

    handleSingleSelectionDataToItems(data) {
      return data.map(e => {
        return {
          value: e.id,
          text: e.value,
        }
      })
    },

    handleItemSelectorInput(event = null) {
      if (this.item.multi && Array.isArray(event)) {
        this.form = [...event]
        return
      }

      this.form = [event].filter(Boolean)
    },

    validate() {
      const input = this.$refs.input
      if (!input) {
        return true
      }

      if (Array.isArray(input)) {
        return input.every(e => e.validate())
      }

      const isValid = input.validate()

      const CustomInputValidIsUndefined = isValid === undefined
      if (CustomInputValidIsUndefined) {
        return true
      }

      const CustomInputInvalidIsNodeElement = isValid instanceof Node
      if (CustomInputInvalidIsNodeElement) {
        return false
      }

      return isValid
    },

    handleFormToValue(item, value) {
      const mapItemID = items => {
        return items
          .map(e => {
            if ([undefined].includes(e?.id)) {
              return null
            }

            return { id: e.id }
          })
          .filter(Boolean)
      }

      if (item.type === 'people') {
        return {
          people: mapItemID(this.form),
        }
      }

      if (item.type === 'select') {
        if (typeof this.form === 'string') {
          return { values: mapItemID([{ id: this.form }]) }
        }

        if (Array.isArray(this.form)) {
          return { values: mapItemID(this.form) }
        }
      }

      return value
    },
    genMetadataObjectWithValue() {
      const item = this.item
      const defaultValue = { [item.type]: this.form }
      const value = this.handleFormToValue(item, defaultValue)

      return {
        id: item.id,
        ...value,
      }
    },
    sendValues() {
      if (this._readOnlyPermission) {
        return
      }

      if (!this._metadataValid) {
        this.$emit('update:item', null)
        return
      }

      const metadata = this.genMetadataObjectWithValue()

      this.$emit('update:item', metadata)
    },

    async getOptions() {
      const item = this.item

      if (item.options) {
        this.selectorsFieldsOptions = item.options
        return Promise.resolve()
      }

      if (this.options) {
        this.selectorsFieldsOptions = this.options
        return Promise.resolve()
      }

      const hasSearch = this.dirtSearch
      const isPeople = ['people'].includes(item.type)
      const hasOptions =
        this.selectorsFieldsOptions && this.selectorsFieldsOptions?.length > 0

      if (!hasSearch && isPeople && hasOptions) {
        return Promise.resolve()
      }

      this.dirtSearch = false
      switch (item.type) {
        case 'people':
          return await this.getPersonOptions()
        default:
          return Promise.resolve()
      }
    },
    async getPersonOptions() {
      this.selectorsFieldsLoading['people'] = true
      await getPeople({
        limit: 30,
        offset: 0,
        showInactive: this.showInactive,
      }).then(res => {
        const items = res.data.map(e => {
          return {
            ...e,
            id: e.id,
            label: e.name,
            email: e.email,
            image: e.photo,
            groups: e.groups?.map(g => g.name) || [],
            inactive: this.handlePersonInactive(e),
          }
        })

        this.selectorsFieldsOptions = items

        return items
      })
      this.selectorsFieldsLoading['people'] = false
    },

    handlePersonInactive(person) {
      if (!person) return false
      if (person.status === 'inactive') return true
    },

    getOptionsSearch(search) {
      if (!search) return

      this.dirtSearch = true
      switch (this.item.type) {
        case 'people':
          this.personSearch(search)
          break
        default:
          break
      }
    },
    async personSearch(search = '') {
      const payload = {
        limit: 20,
        offset: 0,
        name: search,
        showInactive: this.showInactive,
      }

      this.selectorsFieldsLoading['people'] = true
      await getPeople(payload).then(res => {
        const items = res.data.map(el => {
          return {
            ...el,
            id: el.id,
            label: el.name,
            email: el.email,
            image: el.photo,
            groups: el.groups?.map(g => g.name) || [],
            inactive: this.handlePersonInactive(el),
          }
        })

        this.selectorsFieldsOptions = items
        return items
      })
      this.selectorsFieldsLoading['people'] = false
    },

    async handleCurrentValue(val) {
      const findOptionIn = value => {
        const isInsideItems = this.item.multi || this.item.type === 'people'
        if (isInsideItems) {
          return this._selectorsFieldsOptions[0].items.find(e => e.id === value)
        }

        return this._selectorsFieldsOptions.find(e => e.id === value)
      }

      if (val && this.watchCurrent) {
        if (!this.selectorsFieldsOptions.length) {
          await this.getOptions()
        }

        if (typeof val === 'string' || Array.isArray(val)) {
          this.form = val
          return
        }

        if (typeof val === 'object') {
          if (Object.keys(val).length === 0) {
            this.form = null
            return
          }

          Object.keys(val).some(key => {
            const check_keys = ['text', 'date', 'people', 'values']
            if (!check_keys.includes(key)) {
              return false
            }

            const isArrayEmpty =
              Array.isArray(val[key]) && val[key].length === 0

            const isStringEmpty =
              typeof val[key] === 'string' && val[key].length === 0

            if (isArrayEmpty || isStringEmpty) {
              this.form = null
              return true
            }

            if (['text', 'date'].includes(key)) {
              this.form = val[key]
              return true
            }

            if (this.item.multi || key === 'people') {
              this.form = val[key].map(e => findOptionIn(e.id))
              return true
            }

            if (Array.isArray(val[key])) {
              this.form = val[key][0]?.id
              return true
            }
          })
        }
      }
    },
  },

  mounted() {
    this.handleCurrentValue(this.currentValue)
  },

  watch: {
    form: {
      handler() {
        clearTimeout(this.debounceTimeOut)
        this.debounceTimeOut = setTimeout(() => {
          this.sendValues()
        }, this.timeDebounce)
      },
      deep: true,
    },

    currentValue(newValue, oldValue) {
      if (JSON.stringify(oldValue) === JSON.stringify(newValue)) {
        return
      }

      this.handleCurrentValue(newValue)
    },

    options(val) {
      this.selectorsFieldsOptions = val
    },
  },
}
</script>
