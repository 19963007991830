<template>
  <v-autocomplete
    v-model="activeSelectedItem"
    :placeholder="inputLabel"
    :items="_items"
    :attach="attach"
    :dense="dense"
    :search-input.sync="search"
    class="FindSearch"
    prepend-inner-icon="mdi-magnify"
    color="neutral-5"
    hide-details="auto"
    item-text="label"
    item-value="id"
    return-object
    :no-data-text="$t('itemSelector.noResults')"
    @update:search-input="handleSearch($event)"
    @change="handleChange"
    @focus="focus()"
    @blur="focused = false"
    :menuProps="{
      contentClass: 'FindSearch__menu',
      transition: 'fade-transition',
    }"
  >
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    inputLabel: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    attach: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
      search: '',
      interval: null,
      activeSelectedItem: null,
    }
  },
  methods: {
    handleSearch(event) {
      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.searchSubmit(event)
      }, 200)
    },

    searchSubmit(value) {
      this.$emit('search:item', value)
    },

    handleChange(value) {
      this.$emit('update:item', value)
    },

    focus() {
      if (this.focused) return

      this.focused = true
      this.$emit('focus:input')
    },
  },
  computed: {
    _items() {
      if (this.hideSelected && Array.isArray(this.selectedItems))
        return this.items.filter(item => {
          return !this.selectedItems.find(
            selectedItem =>
              selectedItem &&
              'id' in selectedItem &&
              selectedItem.id === item.id
          )
        })
      else return this.items
    },
    colors() {
      return require('@/styles/scss/_colors.scss')
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
