import _EventBus from '@/plugins/eventBus'

export function alertErrorMessage(title, error) {
  const payloadAlert = {
    title: title,
    type: 'error',
    hasLeftBorder: true,
  }

  if (typeof error === 'string') {
    Object.assign(payloadAlert, {
      description: error,
    })

    return payloadAlert
  }

  const hasResponseApiData = error?.response?.data
  const hasMessage =
    hasResponseApiData?.message &&
    typeof hasResponseApiData.message === 'string'

  if (hasMessage) {
    Object.assign(payloadAlert, {
      description: hasResponseApiData.message,
    })
  }

  return payloadAlert
}

export function alertSuccessMessage(title, description) {
  const payloadAlert = {
    title: title,
    type: 'success',
    hasLeftBorder: true,
  }

  if (typeof description === 'string') {
    Object.assign(payloadAlert, {
      description: description,
    })
  }

  return payloadAlert
}

export function alertErrorBus(title, error, customAlertHandler = null) {
  const message = alertErrorMessage(title, error)

  if (typeof customAlertHandler === 'function') {
    customAlertHandler(message)
    return
  }

  _EventBus.$emit('bus:alert', message)
}

export function alertSuccessBus(title, description, customAlertHandler = null) {
  const message = alertSuccessMessage(title, description)

  if (typeof customAlertHandler === 'function') {
    customAlertHandler(message)
    return
  }

  _EventBus.$emit('bus:alert', message)
}
