<template>
  <v-menu
    v-model="model"
    content-class="notification-menu"
    z-index="999"
    :close-on-content-click="false"
    data-test-notifications-menu
    offset-x
    right
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        text
        tile
        elevation="0"
        class="notification-icon"
        :class="{ mini: isMiniVariant }"
        data-test-notifications-btn
        icon
        v-on="on"
      >
        <v-badge
          color="primary"
          offset-x="10"
          offset-y="10"
          data-test-notifications-badge
          :content="unreadedCount"
          :value="unreadedCount"
        >
          <v-icon size="1.25rem">fi-br-bell</v-icon>
        </v-badge>
        <span
          class="notification-icon--label"
          v-text="$t('ui-components.siteNavigation.notifications')"
        ></span>
      </v-btn>
    </template>

    <div class="notification-card" data-test-notifications-content>
      <div class="menu-header">
        <span class="title-menu">
          {{ $t('notificationPage.notificationMenu.title') }}
        </span>

        <v-avatar v-if="unreadedCount > 0" color="primary" size="1rem">
          <span class="counter-label">
            {{ unreadedCount }}
          </span>
        </v-avatar>

        <v-spacer></v-spacer>

        <span
          class="action"
          data-test-notifications-mark-all
          @click="readAll()"
        >
          {{ $t('notificationPage.notificationMenu.markAll') }}
        </span>
      </div>

      <div
        v-if="notifications.length > 0"
        class="menu-content"
        data-test-notifications-list
        @scroll="handleScroll"
      >
        <NotificationItem
          v-for="(item, i) in notifications"
          :key="i"
          :item="item"
          @action:click="handleRead($event)"
          @content:click="handleNotificationContentClick"
        ></NotificationItem>
      </div>

      <div v-else class="menu-content" data-test-notifications-empty>
        <div class="empty">
          {{ $t('notificationPage.notificationMenu.empty') }}
        </div>
      </div>

      <div class="menu-footer">
        <span
          class="action"
          data-test-notifications-show-all
          @click="showAll()"
        >
          {{ $t('notificationPage.notificationMenu.showAll') }}
        </span>
      </div>
    </div>
  </v-menu>
</template>

<script>
import {
  getNotifications,
  markAllAsRead,
  markAsRead,
  markAsUnread,
} from '@/services/notification'

import { handleDefaultNotificationDataToItems } from '@/helpers/notifications/notifications'

export default {
  name: 'NotificationMenu',
  props: {
    isMiniVariant: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      model: false,
      interval: null,
      loading: false,
      pagination: {
        limit: 10,
        offset: 0,
        total: 0,
      },
      unreadedCount: 0,
      notifications: [],
      readNotificationIsLoading: false,
    }
  },

  mounted() {
    this.getNotifications({
      limit: this.pagination.limit,
      offset: this.pagination.offset,
    })

    this.interval = setInterval(() => {
      this.getNotifications({
        limit: this.notifications.length || this.pagination.limit,
        offset: 0,
      })
    }, 300000)

    this.$root.$on('read-notifications', this.readNotificationsEvent)

    this.$root.$on('read-notification:id', this.handleNotificationID)
  },

  beforeDestroy() {
    clearInterval(this.interval)

    this.$root.$off('read-notifications', this.readNotificationsEvent)

    this.$root.$off('read-notification:id', this.handleNotificationID)
  },

  methods: {
    async getNotifications(params = {}, infinity = false) {
      if (this.loading) return

      this.loading = true

      const payload = {
        ...params,
        mine: true,
        sortOrder: 'desc',
        sortBy: 'sentAt',
      }

      const { data, headers } = await getNotifications(payload)

      const notifications = handleDefaultNotificationDataToItems(data)

      this.unreadedCount = parseInt(headers['x-unseen-count']) || 0
      this.pagination.total = parseInt(headers['x-count']) || 0

      this.notifications = infinity
        ? this.notifications.concat(notifications)
        : notifications

      this.loading = false
    },

    handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.notifications.length >= this.pagination.total) return

        this.pagination.offset = this.pagination.offset + this.pagination.limit

        this.getNotifications(
          {
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          },
          true
        )
      }
    },

    async readAll() {
      await markAllAsRead()
        .then(() => {
          this.$root.$emit('read-notifications')
        })
        .catch(() => {
          const message = {
            title: this.$t('notificationPage.notificationMenu.errorReadAll'),
            type: 'error',
            hasIconTitle: true,
          }

          this.$emit('show-alert', message)
        })
    },

    handleRead(event) {
      const ntf = event.item

      if (!ntf.seen) return this.readNotification(ntf.id)
      this.unreadNotification(ntf.id)
    },

    async readNotification(id) {
      if (this.readNotificationIsLoading) {
        return
      }

      this.readNotificationIsLoading = true

      await markAsRead(id)
        .then(() => {
          this.$root.$emit('read-notification:id', { id: id, seen: true })
        })
        .catch(() => {
          const message = {
            title: this.$t('notificationPage.notificationMenu.errorRead'),
            type: 'error',
            hasIconTitle: true,
          }

          this.$emit('show-alert', message)
        })
        .finally(() => {
          this.readNotificationIsLoading = false
        })
    },

    async unreadNotification(id) {
      if (this.readNotificationIsLoading) {
        return
      }

      this.readNotificationIsLoading = true

      await markAsUnread(id)
        .then(() => {
          this.$root.$emit('read-notification:id', { id: id, seen: false })
        })
        .catch(() => {
          const message = {
            title: this.$t('notificationPage.notificationMenu.errorUnread'),
            type: 'error',
            hasIconTitle: true,
          }

          this.$emit('show-alert', message)
        })
        .finally(() => {
          this.readNotificationIsLoading = false
        })
    },

    handleNotificationID({ id, seen }) {
      const index = this.notifications.findIndex(el => el.id === id)

      if (index >= 0) this.notifications[index].seen = seen
      if (this.unreadedCount >= 0) this.unreadedCount += seen ? -1 : 1
    },

    showAll() {
      this.getNotifications({
        limit: this.notifications.length || this.pagination.limit,
        offset: 0,
      })

      if (this.$route.path.includes('/notification/notification-list')) return

      this.$router.push({ path: '/notification/notification-list' })
      this.model = false
    },

    handleNotificationContentClick({ item: notification }) {
      const hasEntityToRedirect = !!notification?.data?.entity
      if (!hasEntityToRedirect) {
        return
      }

      this.$root.$emit('view:entity', 'edit', notification.data.entity)
    },

    readNotificationsEvent() {
      this.getNotifications({
        limit: this.notifications.length || this.pagination.limit,
        offset: 0,
      })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
