<script>
import EntityFilters from '@/views/Performance/Views/LateralMenu/Partials/EntityFilters/EntityFilters.vue'

import { handleFilters } from '@/helpers/filters/sendingCenter'

export default {
  name: 'SendingCenterFilters',
  extends: EntityFilters,
  props: {
    /**
     * @type {Object}
     * @property {filterField} fields
     */
    selectors: {
      type: Object,
      default: () => ({
        fields: [
          /**
           * @type {Object} filterField
           * @property {String} id
           * @property {String} label - pode ser uma chave de internacionalização correspondente ao "sendingCenterFilter.fields"; fallback para id, fallback para proprio texto.
           * @property {Array} options
           */
          { id: 'cycleID' },
          { id: 'dateRange', options: null },
          { id: 'creatorIDs', label: 'creator', options: null },
          { id: 'channel', label: 'channel', options: null },
          { id: 'status', label: 'status', options: null },
          { id: 'roleIDs', label: 'groupsRoles', options: null },
          { id: 'accessProfileIDs', label: 'entityRoles', options: null },
          { id: 'personIDs', label: 'peoples', options: null },
          {
            id: 'ownersEntityHierarchiesIDs',
            label: 'ownersEntityHierarchiesIDs',
            options: null,
          },
        ],
      }),
    },
  },
  data() {
    return {
      form: {
        filter: {},
      },
    }
  },

  computed: {
    _storagePreferences() {
      return false
    },
  },

  methods: {
    handleSelectors({ fields }) {
      const hidratedFields = handleFilters(fields)

      return {
        fields: hidratedFields,
      }
    },

    calcDateRange(date) {
      if (!date) return null

      return date
    },

    sendFilterValues(values) {
      let filters = structuredClone(values)

      const ArrayOrNull = (items, key) => {
        if (!items) return null
        return [...items].length > 0 ? items.map(item => item[key]) : null
      }

      Object.keys(filters).forEach(key => {
        if (
          [
            'creatorIDs',
            'roleIDs',
            'personIDs',
            'accessProfileIDs',
            'channel',
            'status',
            'ownersEntityHierarchiesIDs',
          ].includes(key)
        ) {
          filters[key] = ArrayOrNull(filters[key], 'id')
        }

        if (['cycleID'].includes(key)) {
          filters[key] = filters[key]?.[0]?.id ?? null
        }

        if (['dateRange'].includes(key)) {
          const [startDate = null, endDate = null] = filters[key] ?? []

          filters['startDate'] = this.calcDateRange(startDate)
          filters['endDate'] = this.calcDateRange(endDate)

          delete filters[key]
        }
      })

      filters = this.handleFilterDateRange(filters)

      this.$root.$emit('filter:entity:changes', filters)
    },
    handleFilterDateRange(filters) {
      if (!filters.endDate && filters.startDate) {
        filters.endDate = filters.startDate
      }

      if (filters.startDate <= filters.endDate) {
        return filters
      }

      return filters
    },

    handleApiItens() {
      if (!this.$refs.ComposeFilters) {
        return
      }
    },
  },
}
</script>

<style
  src="@/views/Performance/Views/LateralMenu/Partials/EntityFilters/style.scss"
  lang="scss"
  scoped
></style>
