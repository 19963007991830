import variables from '@/styles/abstracts/_colors.module.scss'

import i18n from '@/plugins/i18n'
import router from '@/router'

import * as _canKey from '@/helpers/ability/permissions'

/** @param {URL} url */
export function toLogin(url, basePath = '') {
  const token = localStorage.getItem('teamculture:token')

  if (token) {
    url.pathname = basePath + '/login'
    url.searchParams.set('t', token)
  }

  return url.toString()
}

export function genSiteNavigationItens(onClickLogout = () => {}) {
  const CDN_BASE_URL = process.env.VUE_APP_CDN_ASSETS_BASE_URL

  return {
    userLinks: {
      logout: {
        click: onClickLogout,
        text: i18n.t('ui-components.siteNavigation.user.logout'),
      },
      help: {
        url: i18n.t('ui-components.siteNavigation.helpInfoLink'),
        text: i18n.t('ui-components.siteNavigation.help'),
      },
      settings: {
        url: '#',
        text: i18n.t('ui-components.siteNavigation.user.settings'),
      },
    },
    menuLinks: [
      {
        name: 'Performance',
        url: null,
        image: {
          src: `${CDN_BASE_URL}/images/navigation/performance-active.svg`,
          title: i18n.t('ui-components.siteNavigation.Performance'),
          color: variables.blueColor7,
          current: true,
        },
        permissioned: _canKey.performance,
        customEvents: {
          click: () => {
            const navigation = { name: 'TreeViewPage' }
            if (router.currentRoute.name !== navigation.name) {
              router.push(navigation)
            }
          },
        },
        ariaLabel: i18n.t('navBar.accessibility.performance'),
      },
      {
        name: 'Management',
        url: process.env.VUE_APP_MANAGEMENT_LINK,
        image: {
          src: `${CDN_BASE_URL}/images/navigation/management.svg`,
          title: i18n.t('ui-components.siteNavigation.Management'),
          color: variables.grayColor12,
        },
        permissioned: _canKey.management,
        ariaLabel: i18n.t('navBar.accessibility.management'),
      },
    ],
  }
}
