import Vue from 'vue'

import VueRouter from 'vue-router'

Vue.use(VueRouter)

import { moduleRoutes } from './Routes'
import { beforeEachRouterEntry } from './before-each-router'

const routes = [...moduleRoutes]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE_URL,
  routes,
})

router.beforeEach(beforeEachRouterEntry)

export default router
