/**
 * @param {import('axios').AxiosRequestConfig} config
 */
export function onFulfilledRequest(config) {
  const _i18nKey = config.url

  if (config.url.includes(':')) {
    const urlPath = config.url.split('/')
    const urlPatternParamsRequired = config.url
      .match(/:(\w+)/g)
      .map(param => param.slice(1))

    urlPatternParamsRequired.forEach(param => {
      if (config?._params?.[param]) {
        const paramValue = config._params[param]
        const paramIndex = urlPath.indexOf(`:${param}`)

        if (paramIndex > -1) {
          urlPath[paramIndex] = paramValue
        }
      }
    })

    config.url = urlPath.join('/')
  }

  return Object.assign(config, { _i18nKey })
}
