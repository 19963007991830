var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-test-sidebar-entity-filter":""}},[_c('LateralMenuItem',{attrs:{"title":_vm.$t('entityFilters.menuItem.title'),"is-mini":_vm.isMini},scopedSlots:_vm._u([{key:"title-icon",fn:function({ size }){return [_c('v-icon',{attrs:{"size":size}},[_vm._v("fi-sr-filter")])]}},{key:"header-action",fn:function(){return [_c('v-btn',{staticClass:"pa-1",attrs:{"data-test-clean-entity-filter":"","plain":"","text":"","small":""},on:{"click":function($event){return _vm.cleanEntityFilter()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('entityFilters.menuItem.actions.clean')))])])]},proxy:true}])},[_c('ComposeFilters',{ref:"ComposeFilters",staticClass:"entity-filter",attrs:{"form-name":_vm.filterName,"selectors":_vm._selectors,"active-cycle-i-ds":_vm.activeCycleIDs,"storage-preferences":_vm._storagePreferences},on:{"compose:values":_vm.setFiltersValue,"api:itens":_vm.handleApiItens,"mounted":_vm.ComposeFiltersMounted},scopedSlots:_vm._u([{key:"metadata",fn:function({
          field,
          fieldsValues,
          fieldsOptions,
          getOptions,
          getOptionsSearch,
          setValue,
          getComposeFormPreferences,
          setComposeFormPreferences,
        }){return [_c('MetadataSelector',{ref:"MetadataFilters",attrs:{"fields-options":field.options || fieldsOptions[field.id],"pre-selected-fields":getComposeFormPreferences({ name: '-' + field.id }, _vm.filterName),"pre-value":fieldsValues[field.id] || [],"show-inactive":true},on:{"focus:input":function($event){return getOptions(field.id)},"search:item":function($event){return getOptionsSearch(field.id, $event)},"update:item":function($event){return setValue($event, field.id, field.type)},"update:fields":function($event){return setComposeFormPreferences(
              false,
              {
                name: '-' + field.id,
                data: $event,
              },
              _vm.filterName
            )}}})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }