import api from '@/services/Http'
import { extractSignal, constructParams } from '@/helpers/api'

export const getRolesGroup = async data => {
  const signal = extractSignal(data)
  const params = constructParams(
    ['limit', 'offset', 'module', 'label', 'activeCycleIDs'],
    data
  )

  return (await api()).get('/management/groups/roles', {
    ...signal,
    params: {
      module: 'performance',
      ...params,
    },
  })
}
