import i18n from '@/plugins/i18n'

import { getCycles } from '@/services/cycles'
import { getPeople } from '@/services/people'
import { getRolesGroup } from '@/services/rolesGroup'
import {
  getEntitiesAccessProfiles,
  getEntitiesHierarchySettings,
} from '@/services/entitiesOKR'
import { getNotificationSendChannels } from '@/services/notification'

import { hidrateFnByEntity } from '@/helpers/filters/okrs'
import { hidratePeopleFn } from '@/helpers/okrs/metadatas'

function constructFilterItemSelector(filter) {
  return {
    type: 'ItemSelector',

    submit: {
      type: 'ArrayOf',
      identifier: 'id',
    },

    ItemSelector: {
      localSearch: filter.localSearch,

      menuConfig: {
        align: 'right',
        attach: false,
        showTabs: false,
      },

      inputConfig: {
        closeOnSelect: !filter.multiple,

        showAvatar: ['creatorIDs', 'personIDs'].includes(filter.id),

        showElements: !['status'].includes(filter.id),

        clearable: true,
        itemsToPresent: 2,
      },

      type: filter.type,
      multiple: filter.multiple,
    },
  }
}

function constructFilterCustomDatePicker(filter) {
  return {
    type: 'CustomDatePicker',

    CustomDatePicker: {
      dateTime: filter.dateTime,
      multiple: filter.multiple,
      range: filter.range,
    },
  }
}

function getConstructor(filterID) {
  const FiltesTypesByID = {
    dateRange: 'CustomDatePicker',
    cycleID: 'ItemSelector',
    status: 'ItemSelector',
    channel: 'ItemSelector',
    creatorIDs: 'ItemSelector',
    personIDs: 'ItemSelector',
    roleIDs: 'ItemSelector',
    accessProfileIDs: 'ItemSelector',
    ownersEntityHierarchiesIDs: 'ItemSelector',
  }
  const type = FiltesTypesByID[filterID]

  const constructorsByType = {
    ItemSelector: constructFilterItemSelector,
    CustomDatePicker: constructFilterCustomDatePicker,
  }
  const construct = constructorsByType[type]

  if (!construct) {
    return null
  }

  return construct
}

function constructFilterApi(filterID) {
  const payloadByID = {
    cycleID: {
      endpoint: getCycles,
      searchKey: 'title',
      infinity: true,
      limit: 30,
      identifier: 'id',
      hidrateFn: item => ({
        ...item,
        label: item.title,
      }),
    },
    personIDs: {
      endpoint: getPeople,
      searchKey: 'name',
      infinity: true,
      limit: 30,
      identifier: 'id',
      hidrateFn: hidratePeopleFn,
      payload: {
        showInactive: true,
      },
    },
    creatorIDs: {
      endpoint: getPeople,
      searchKey: 'name',
      infinity: true,
      limit: 30,
      identifier: 'id',
      hidrateFn: hidratePeopleFn,
      payload: {
        showInactive: true,
      },
    },
    roleIDs: {
      endpoint: getRolesGroup,
      searchKey: 'label',
      infinity: true,
      limit: 30,
      identifier: 'id',
      payload: {
        module: 'performance',
      },
    },
    accessProfileIDs: {
      endpoint: getEntitiesAccessProfiles,
      searchKey: 'label',
      infinity: true,
      limit: 30,
      identifier: 'id',
      hidrateFn: hidrateFnByEntity,
    },
    channel: {
      endpoint: getNotificationSendChannels,
      searchKey: 'label',
      infinity: true,
      limit: 30,
      identifier: null,
      hidrateFn: item => ({
        id: item,
        label: item,
      }),
    },
    ownersEntityHierarchiesIDs: {
      endpoint: getEntitiesHierarchySettings,
      searchKey: null,
      infinity: false,
      identifier: 'id',
      hidrateFn: item => {
        const calcLabel = item => {
          let label = []

          if (item.entityType) {
            label.push(
              i18n.t(`entityFilters.options.entityTypeShort.${item.entityType}`)
            )
          }

          if (item.entityClass) {
            label.push(
              i18n.t(`entityFilters.options.entityClass.${item.entityClass}`)
            )
          }

          return label.join(' ')
        }

        const term = i18n.t('notificationPage.newNotification.responsible')
        const label = `${term} (${calcLabel(item)})`

        return {
          ...item,
          label: label,
        }
      },
      payload: {
        entityTypeExclusiveParam: 'context',
      },
    },
  }

  const payload = payloadByID[filterID] || null

  return {
    api: payload,
  }
}

function constructFilterOptions(filterID, label = null, type = null) {
  const payloadByID = {
    status: [
      {
        value: filterID,
        label,
        type,
        items: [
          {
            label: i18n.t('sendingCenterFilter.options.status.sent'),
            id: 'sent',
          },
          {
            label: i18n.t('sendingCenterFilter.options.status.scheduled'),
            id: 'scheduled',
          },
          {
            label: i18n.t('sendingCenterFilter.options.status.draft'),
            id: 'draft',
          },
        ],
      },
    ],
  }

  const payload = payloadByID[filterID] || null
  return {
    options: payload,
  }
}

function constructFilterDefault(filterID) {
  const payloadByID = {}

  const payload = payloadByID[filterID] || null
  return {
    default: payload,
  }
}

function constructFilterPreference(filterID, label) {
  const constructLabel = label => {
    if (label) {
      return i18n.t(`sendingCenterFilter.fields.${label}`)
    }

    return i18n.t(`sendingCenterFilter.fields.${filterID}`)
  }

  const payloadByID = {
    dateRange: {
      multiple: false,
      dateTime: false,
      range: true,
    },
    status: {
      multiple: false,
      localSearch: true,
      type: 'listview',
    },
    cycleID: {
      multiple: false,
      localSearch: false,
      type: 'listview',
    },

    personIDs: {
      multiple: true,
      localSearch: false,
      type: 'listview',
    },
    creatorIDs: {
      multiple: true,
      localSearch: false,
      type: 'listview',
    },
    roleIDs: {
      multiple: true,
      localSearch: false,
      type: 'listview',
    },
    channel: {
      multiple: true,
      localSearch: false,
      type: 'listview',
    },
    ownersEntityHierarchiesIDs: {
      multiple: true,
      localSearch: false,
      type: 'listview',
    },
  }

  const payload = payloadByID[filterID] || {}
  return {
    ...payload,
    id: filterID,
    _label: constructLabel(label),
  }
}

function calcFilter(filter) {
  const constructor = getConstructor(filter.id)
  if (!constructor) {
    return null
  }

  const constructorPayload = constructFilterPreference(filter.id, filter?.label)

  const apiPayload = constructFilterApi(filter.id)
  const optionsPayload = constructFilterOptions(
    filter.id,
    constructorPayload?._label,
    constructorPayload?.type
  )
  const defaultPayload = constructFilterDefault(filter.id)
  const payload = constructor(constructorPayload)

  return {
    id: filter.id,
    _label: constructorPayload._label,
    ...payload,
    ...optionsPayload,
    ...apiPayload,
    ...defaultPayload,
    eagerApi: !!filter?.eagerApi,
  }
}

export function handleFilters(filters) {
  return filters.map(filter => calcFilter(filter)).filter(Boolean)
}
