import Shepherd from 'shepherd.js'
import '@/styles/vendors/_shepherd.scss'

const shepherdKey = '$shepherd'
export const useShepherd = (...args) => new Shepherd.Tour(...args)

const install = function installVueShepherd(Vue) {
  if (install.installed) return
  install.installed = true

  Vue.prototype[shepherdKey] = useShepherd
}

const VueShepherdPlugin = { install }

export default VueShepherdPlugin
