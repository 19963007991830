import api from '@/services/Http'
import { constructParams } from '@/helpers/api'
import { buildFetchEntitiesRequestBody } from '../entitiesOKR'

export const newInitiative = async data => {
  try {
    const result = await handleNewInitiative(data)
    return result
  } catch (error) {
    if (error.response.status === 409) {
      return handleNewInitiative(data)
    }

    throw error
  }
}

async function handleNewInitiative(data) {
  return (await api()).post('/performance/okrs/entities/initiatives', data)
}

export async function deleteInitiative(initiativeID) {
  return (await api()).delete(
    `/performance/okrs/entities/initiatives/${initiativeID}`
  )
}

export async function quickEditInitiative(initiativeID, payload) {
  return (await api()).put(
    `/performance/okrs/entities/initiatives/${initiativeID}`,
    payload
  )
}

export async function getInitiativePeople(initiativeID, invited) {
  return (await api()).get(
    `/performance/okrs/entities/initiatives/${initiativeID}/people?invited=${invited}`
  )
}

export async function getAccessProfiles(payload) {
  const params = constructParams(['cycleID', 'activeCycleIDs'], payload)

  return (await api()).get(
    '/performance/okrs/entities/initiatives/access-profiles',
    {
      params: params,
    }
  )
}

export async function updateAccessProfiles(initiativeID, personID, payload) {
  return (await api()).put(
    `/performance/okrs/entities/initiatives/${initiativeID}/people/${personID}`,
    payload
  )
}

export async function getInitiative(initiativeID) {
  return (await api()).get(
    `/performance/okrs/entities/initiatives/${initiativeID}`
  )
}

export async function getWorkFlow() {
  return (await api()).get(
    `/performance/okrs/entities/initiatives/workflow-status`
  )
}

export async function acceptInvite(data, queryParams) {
  return (await api()).put(
    `/performance/okrs/entities/initiatives/${data.entityID}/accept`,
    data,
    {
      params: queryParams,
    }
  )
}
export async function rejectInvite(data, queryParams) {
  return (await api()).put(
    `/performance/okrs/entities/initiatives/${data.entityID}/reject`,
    data.params,
    {
      params: queryParams,
    }
  )
}

export async function getPendingInvitations() {
  return (await api()).get(`/performance/okrs/entities/initiatives/invitations`)
}

export async function getInvitationsWithPost(data) {
  const params = buildFetchEntitiesRequestBody(data)

  return (await api()).post(
    `/performance/okrs/entities/initiatives/invitations`,
    params
  )
}

export async function addCorresponsible(data) {
  return (await api()).put(
    `/performance/okrs/entities/batch/add-coresponsible`,
    data
  )
}

export async function deleteInitiativeOwner(initiativeID, personID) {
  return (await api()).delete(
    `/performance/okrs/entities/initiatives/${initiativeID}/owners/${personID}`
  )
}

export async function updateInitiativeOwner(initiativeID, personID) {
  return (await api()).put(
    `/performance/okrs/entities/initiatives/${initiativeID}/owners/${personID}`
  )
}

export async function deleteAccessProfiles(initiativeID, personID) {
  return (await api()).delete(
    `/performance/okrs/entities/initiatives/${initiativeID}/people/${personID}`
  )
}

export async function replaceCorresponsible(data) {
  return (await api()).put(
    `/performance/okrs/entities/batch/replace-coresponsible`,
    data
  )
}

export async function removeCorresponsible(data) {
  return (await api()).delete(
    `/performance/okrs/entities/batch/remove-coresponsible`,
    { data }
  )
}

export async function getStatusProgress() {
  return (await api()).get(`/performance/okrs/entities/initiatives/status`)
}

export default {
  getInitiative,
  deleteInitiative,
  newInitiative,
  getAccessProfiles,
  getWorkFlow,
  getInitiativePeople,
  acceptInvite,
  rejectInvite,
  quickEditInitiative,
  getPendingInvitations,
  getInvitationsWithPost,
  addCorresponsible,
  replaceCorresponsible,
  getStatusProgress,
}
