var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.item.type,attrs:{"id":"field"}},[(_vm.item.type === 'text')?_c('CustomInput',{ref:"input",attrs:{"label":_vm.item.label,"currentValue":_vm.handleEmptyInputForm(_vm.form),"readonly":_vm._readOnlyPermission,"mandatory":_vm.rules.length > 0,"viewMode":_vm.subtleMode,"suffixTitle":_vm.$t('entityFilters.fields.emptyFilter'),"suffixIcon":'fi-rr-test',"suffixAction":true},on:{"handleInput":function($event){_vm.form = $event},"click:suffix":_vm.handleEmptyFilter}}):_vm._e(),(_vm.item.type === 'select' && !_vm.item.multi)?_c('SingleSelection',{attrs:{"mandatory":false,"label":_vm.item.label,"placeholder":_vm.item.label,"editable":!_vm._readOnlyPermission,"rules":_vm.rules,"items":_vm._selectorsFieldsOptions,"selectedItem":_vm.form || '',"readonly":_vm.subtleMode},on:{"focus:input":function($event){return _vm.getOptions()},"selectOption":function($event){_vm.form = $event.value}}}):_vm._e(),(_vm.item.type === 'select' && _vm.item.multi)?_c('ItemSelector',{ref:"input",attrs:{"multiple":true,"showElements":true,"watchCurrent":true,"fillWidth":true,"localSearch":true,"readonly":_vm._readOnlyPermission,"inputConfig":{
      label: _vm.item.label,
      rules: _vm.rules,
      subtleMode: _vm.subtleMode,
      showAvatar: false,
      clearable: true,
      itemsToPresent: 2,
    },"menuConfig":{
      showTabs: false,
    },"menuOptions":_vm._selectorsFieldsOptions,"currentValue":{
      data: _vm.form || [],
      origin: _vm.item.id,
    }},on:{"focus:input":function($event){return _vm.getOptions()},"update:item":function($event){return _vm.handleItemSelectorInput($event)}}}):_vm._e(),(['people'].includes(_vm.item.type))?_c('ItemSelector',{ref:"input",attrs:{"inputLabel":_vm.item.label,"multiple":_vm.item.multi,"showElements":true,"watchCurrent":true,"fillWidth":true,"localSearch":false,"readonly":_vm._readOnlyPermission,"inputConfig":{
      label: _vm.item.label,
      rules: _vm.rules,
      subtleMode: _vm.subtleMode,
      clearable: true,
      itemsToPresent: 2,
    },"menuConfig":{
      showTabs: false,
    },"menuOptions":_vm._selectorsFieldsOptions,"currentValue":{
      data: _vm.form || [],
      origin: _vm.item.id,
    },"loading":(_vm.selectorsFieldsLoading && _vm.selectorsFieldsLoading['people']) || false},on:{"update:item":function($event){return _vm.handleItemSelectorInput($event)},"focus:input":function($event){return _vm.getOptions()},"search:item":function($event){return _vm.getOptionsSearch($event)}}}):_vm._e(),(_vm.item.type === 'date')?_c('CustomDatePicker',{ref:"input",attrs:{"mandatory":false,"label":_vm.item.label,"closeOnClick":true,"subtleMode":_vm.subtleMode,"menuProps":{
      attach: false,
      'z-index': 9999,
      'content-class': 'mt-1',
    },"textInputProps":{
      'hide-details': 'auto',
    },"rules":_vm.rules,"readonly":_vm._readOnlyPermission,"dateSelected":_vm.handleEmptyInputForm(_vm.form),"suffixTitle":_vm.$t('entityFilters.fields.emptyFilter'),"suffixIcon":'fi-rr-test',"suffixAction":true},on:{"click:suffix":_vm.handleEmptyFilter,"input":function($event){_vm.form = $event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }