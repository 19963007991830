var render, staticRenderFns
import script from "./SendingCenterFilters.vue?vue&type=script&lang=js&"
export * from "./SendingCenterFilters.vue?vue&type=script&lang=js&"
import style0 from "@/views/Performance/Views/LateralMenu/Partials/EntityFilters/style.scss?vue&type=style&index=0&id=1aba04d0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aba04d0",
  null
  
)

export default component.exports