import api from '@/services/Http'
import { extractSignal, constructParams } from '@/helpers/api'

export async function getCycles(data) {
  const signal = extractSignal(data)
  const params = constructParams(['limit', 'offset', 'title', 'status'], data)

  return (await api()).get('/performance/okrs/cycles', {
    ...signal,
    params,
  })
}

export async function getCyclesById(cycleID) {
  return (await api()).get('/performance/okrs/cycles/:cycleID', {
    _params: { cycleID },
  })
}

export async function getCycleTree(cycleID) {
  return (await api()).get('/performance/okrs/cycles/:cycleID/tree', {
    _params: { cycleID },
  })
}

export async function getCycleHierarchy(cycleID) {
  return (await api()).get('/performance/okrs/cycles/:cycleID/hierarchy', {
    _params: { cycleID },
  })
}

export async function getTreeCycles(data) {
  const params = constructParams(['period', 'sortBy', 'sortOrder'], data)

  return (await api()).get('/performance/okrs/cycles/tree', {
    params,
  })
}
