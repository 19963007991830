import { AbilityBuilder, Ability } from '@casl/ability'
import * as performance from './permissions/performance'
import * as management from './permissions/management'
import * as notifications from './permissions/notifications'
import * as libs from './libs'

export const ability = new Ability()

export function abilityHelper(permissions, additional = false) {
  let ability_ = new AbilityBuilder()
  if (additional) {
    ability_ = ability
  }
  const userPermissions = permissions
  userPermissions.map(key => ability_.can('access', key))
  ability.update(ability_.rules)
}

export const NestedGeneralPermissions = Object.keys({
  ...performance,
  ...management,
  ...notifications,
}).reduce((acc, key) => {
  const nestedPermissions = libs.splitUnderlineToNestedObject(key)
  return libs.mergeObjectsRecursively(acc, nestedPermissions)
}, {})

export * from './permissions'
export {
  CanActionOnEntity,
  CanActionOnNotification,
  CanActionOnMetadataOfEntity,
} from './entities'
