import api from '@/services/Http'

export async function getDescription() {
  return (await api()).get('/management/account/description')
}

export async function putDescription(payload) {
  return (await api()).put('/management/account/description', payload)
}

export async function getAccount() {
  return (await api()).get('/management/account')
}

export async function getAccountMe(data) {
  return (await api()).get('/management/account/me', { params: data })
}

export async function putAccountMe(payload) {
  return (await api()).put('/management/account/me', payload)
}

export async function getManagementAccountPerformanceSettings() {
  return (await api()).get('/management/account/settings/performance')
}
