// [notifications]
export const notifications = 'notifications'
export const notifications_sendingCenter = 'notifications.sending-center'

// [sending-center]
export const notifications_sendingCenter_create =
  'notifications.sending-center.create'
export const notifications_sendingCenter_edit =
  'notifications.sending-center.edit'
export const notifications_sendingCenter_delete =
  'notifications.sending-center.delete'
export const notifications_sendingCenter_duplicate =
  'notifications.sending-center.duplicate'
