<template>
  <LateralMenu v-if="!blockLateralMenu">
    <template #default="{ isMini }">
      <EntityFilters
        v-if="showEntityFilters"
        ref="entityFilters"
        :is-mini="isMini"
        :selectors="selectors"
        :filter-name="filterName"
        :active-cycle-i-ds="_activeCycleIDs"
      ></EntityFilters>

      <NotificationCenterFilters
        v-if="_showNotificationCenterFilters"
        :is-mini="isMini"
        :filter-name="'notification'"
      ></NotificationCenterFilters>

      <SendingCenterFilters
        v-if="_showSendingCenterFilters"
        :is-mini="isMini"
        :filter-name="'notification-sending'"
      ></SendingCenterFilters>
    </template>
  </LateralMenu>
</template>

<script>
import EntityFilters from './Partials/EntityFilters/EntityFilters.vue'
import NotificationCenterFilters from './Partials/NotificationCenterFilters/NotificationCenterFilters.vue'
import SendingCenterFilters from './Partials/SendingCenterFilters/SendingCenterFilters.vue'

import { performance_entities_deleted } from '@/helpers/ability'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'LateralMenuView',
  components: {
    EntityFilters,
    NotificationCenterFilters,
    SendingCenterFilters,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },

    inputConfig: {
      type: Object,
      default: () => ({}),
    },

    menuConfig: {
      type: Object,
      default: () => ({}),
    },

    loading: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectors: { fields: [] },
      okrsSelectors: {
        fields: [
          {
            id: 'deleted',
            options: [
              {
                id: false,
                msg: this.$t('entityFilters.fields.deletedEntity.no'),
              },
              {
                id: true,
                msg: this.$t('entityFilters.fields.deletedEntity.yes'),
              },
            ],
            label: 'showDeletedEntity',
            default: false,
          },
          {
            id: 'hasInactivePerson',
            options: [
              {
                id: null,
                msg: this.$t('entityFilters.fields.peopleInactiveOptions.no'),
              },
              {
                id: true,
                msg: this.$t('entityFilters.fields.peopleInactiveOptions.yes'),
              },
            ],
            label: 'peopleInactive',
            default: null,
          },
          { id: 'entityIDs' },
          { id: 'entityType' },
          { id: 'entityClass' },
          { id: 'ownerIDs', label: 'owners' },
          { id: 'peopleGroupIDs', label: 'groups' },
          { id: 'personIDs', label: 'peoples' },
          { id: 'accessProfileIDs', label: 'ownersRoles' },
          { id: 'workflowStatusIDs', label: 'workflowStatus' },
          { id: 'tagIDs', label: 'tags' },
          { id: 'metadata' },
        ],
      },
      initiativesSelectors: {
        fields: [
          {
            id: 'directGroupOnly',
            options: [
              {
                id: 'all',
                msg: this.$t('entityFilters.fields.directGroupOnly.all'),
              },
              {
                id: 'only',
                msg: this.$t('entityFilters.fields.directGroupOnly.only'),
              },
            ],
            label: 'exibitionInitiatives',
            default: 'all',
          },
          { id: 'entityIDs' },
          { id: 'workflowStatusIDs', label: 'workflowStatus' },
          { id: 'status', label: 'statusProgress' },
          { id: 'ownerIDs', label: 'owners' },
          { id: 'peopleGroupIDs', label: 'groups' },
          { id: 'personIDs', label: 'peoples' },
          { id: 'accessProfileIDs', label: 'ownersRoles' },
          { id: 'tagIDs', label: 'tags' },
          { id: 'metadata' },
        ],
      },
      coresponsibilityRequestsSelectors: {
        fields: [
          {
            id: 'directGroupOnly',
            options: [
              {
                id: 'all',
                msg: this.$t('entityFilters.fields.directGroupOnly.all'),
              },
              {
                id: 'only',
                msg: this.$t('entityFilters.fields.directGroupOnly.only'),
              },
            ],
            label: 'exibitionInitiatives',
            default: 'all',
          },
          { id: 'workflowStatusIDs', label: 'workflowStatus', eagerApi: true },
          { id: 'ownerIDs', label: 'owners' },
          { id: 'peopleGroupIDs', label: 'groups' },
          { id: 'personIDs', label: 'peoples' },
          { id: 'accessProfileIDs', label: 'ownersRoles', eagerApi: true },
          { id: 'invitationStatus', label: 'invitationStatus' },
          { id: 'tagIDs', label: 'tags' },
          { id: 'metadata' },
        ],
      },
      kpisSelectors: {
        fields: [
          {
            id: 'deleted',
            options: [
              {
                id: false,
                msg: this.$t('entityFilters.fields.deletedEntity.no'),
              },
              {
                id: true,
                msg: this.$t('entityFilters.fields.deletedEntity.yes'),
              },
            ],
            label: 'showDeletedEntity',
            default: false,
          },
          {
            id: 'hasInactivePerson',
            options: [
              {
                id: null,
                msg: this.$t('entityFilters.fields.peopleInactiveOptions.no'),
              },
              {
                id: true,
                msg: this.$t('entityFilters.fields.peopleInactiveOptions.yes'),
              },
            ],
            label: 'peopleInactive',
            default: null,
          },

          { id: 'ownerIDs', label: 'owners' },
          { id: 'peopleGroupIDs', label: 'groups' },
          { id: 'personIDs', label: 'peoples' },
          { id: 'accessProfileIDs', label: 'ownersRoles' },
          { id: 'workflowStatusIDs', label: 'workflowStatus' },
          { id: 'tagIDs', label: 'tags' },
          { id: 'metadata' },
        ],
      },
      filterName: '',
    }
  },
  computed: {
    ...mapState(['activeCycles']),
    ...mapGetters({
      user: 'currentUser/user',
    }),

    _canShowEntitiesDeleted() {
      return this.$can('access', performance_entities_deleted)
    },

    _activeCycleIDs() {
      return this.activeCycles.map(cycle => cycle.id)
    },
    getCurrentContext() {
      const routeName = this.$route.name

      const contexts = {
        OverviewPage: 'overview',

        ManagementReportPage: 'management-report',

        TreeViewPage: 'treeview',
        TreeViewPageWithEntityId: 'treeview',

        ListViewPage: 'listview',
        ListViewPageWithEntityId: 'listview',

        KPIsListViewPage: 'KPIsListViewPage',
        KPIsListViewPageWithEntityId: 'KPIsListViewPage',

        KPIsTreeViewPage: 'KPIsTreeViewPage',
        KPIsTreeViewPageWithEntityId: 'KPIsTreeViewPage',

        AllPage: 'all',
        AllPageWithEntityId: 'all',

        CoresponsibilityRequestsPage: 'coresponsibility-requests',
        CoresponsibilityRequestsPageWithEntityId: 'coresponsibility-requests',

        SendingCenter: 'sending-center',
        SendingCenterWithNotificationId: 'sending-center',

        NotificationList: 'notification-list',
        NotificationListWithEntityId: 'notification-list',

        GoalsDashboards: 'GoalsDashboards',
        MyGoalDashboard: 'MyGoalDashboard',
        ComparisonDashboard: 'ComparisonDashboard',
      }

      return contexts[routeName] || ''
    },
    blockLateralMenu() {
      const blockedContexts = [
        'KPIsTreeViewPage',
        'treeview',
        'overview',
        'management-report',
        'GoalsDashboards',
        'MyGoalDashboard',
        'ComparisonDashboard',
      ]
      return blockedContexts.includes(this.getCurrentContext)
    },
    showEntityFilters() {
      const allowedContexts = [
        'listview',
        'all',
        'coresponsibility-requests',
        'KPIsListViewPage',
      ]

      return allowedContexts.includes(this.getCurrentContext)
    },
    _showNotificationCenterFilters() {
      const allowedContexts = ['notification-list']

      return allowedContexts.includes(this.getCurrentContext)
    },
    _showSendingCenterFilters() {
      const allowedContexts = ['sending-center']

      return allowedContexts.includes(this.getCurrentContext)
    },
  },

  watch: {
    '$route.name'() {
      this.defineSelectors()
    },

    _canShowEntitiesDeleted() {
      this.defineSelectors()
    },

    user: {
      handler() {
        this.posCoresponsibilityDefault()
      },
      deep: true,
    },
  },

  created() {
    this.defineSelectors()
  },
  methods: {
    defineSelectors() {
      const context = this.getCurrentContext

      this.filterName =
        {
          listview: 'okrs',
          all: 'initiative',
          'coresponsibility-requests': 'coresponsibility',
          KPIsListViewPage: 'kpis',
        }[context] || ''

      const selectors = {
        listview: this.okrsSelectors,
        all: this.initiativesSelectors,
        'coresponsibility-requests': this.coresponsibilityRequestsSelectors,
        KPIsListViewPage: this.kpisSelectors,
      }[context] || { fields: [] }

      this.selectors = {
        fields: selectors.fields.filter(field => {
          if (field.id === 'deleted') {
            return this._canShowEntitiesDeleted
          }

          return true
        }),
      }

      this.posCoresponsibilityDefault()
    },

    posCoresponsibilityDefault() {
      const context = this.getCurrentContext
      if (context !== 'coresponsibility-requests') return

      this.$nextTick(() => {
        if (!this.user?.id) {
          return
        }

        this.$refs.entityFilters.defineFieldsAsValue(
          [
            {
              ...this.user,
              label: this.user.name,
              image: this.user.photo,
            },
          ],
          'personIDs'
        )
      })
    },
  },
}
</script>
