const { Buffer } = require('buffer')

function removingTextStyle(string) {
  return string.replace(/(<([^>]+)>)/gi, '')
}

function htmlEncode(str) {
  return String(str).replace(/[^\w. ]/gi, function (c) {
    return '&#' + c.charCodeAt(0) + ';'
  })
}

function firstLetterToUpperCase(string) {
  return string[0].toUpperCase() + string.slice(1)
}

function formatWithLocale(value, decimal = 2) {
  const og = Math.pow(10, decimal)
  value = Math.floor(value * og) / og

  const locale = this.$t ? this.$t('dateLocale') : 'pt-BR'

  return Number(value).toLocaleString(locale, {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  })
}

function formatAmount(value, decimal = 2) {
  if (!value) value = 0

  return formatWithLocale(value, decimal)
}

function formatAmountSimpleBR(value, toBR = true) {
  if (value === null || value === undefined) {
    return null
  }

  let unparsed = value
  if (['number', 'object'].includes(typeof unparsed)) {
    unparsed = unparsed.toString()
  }

  unparsed = toBR ? unparsed.replace('.', ',') : formatAmountSimple(unparsed)
  return unparsed
}

function formatAmountSimple(value) {
  const unparsed = value.replace(',', '.')
  const parsed = parseFloat(unparsed)

  return isValidNumber(parsed)
}

const isValidNumber = value => {
  if (typeof parsed === 'number' && !Number.isNaN(value)) {
    return value
  }

  return null
}

function formatLocaleToValue(value) {
  const locale = this.$t ? this.$t('dateLocale') : 'pt-BR'
  let parts = (1234.5).toLocaleString(locale).match(/(\D+)/g)

  let unformatted = value
  unformatted = unformatted.split(parts[0]).join('')
  unformatted = unformatted.split(parts[1]).join('.')

  const parsed = parseFloat(unformatted)
  return isValidNumber(parsed)
}

// https://github.com/validatorjs/validator.js/blob/master/src/lib/isBase64.js
const isBase64 = str => {
  const notBase64 = /[^A-Z0-9+/=]/i
  const len = str.length

  if (len % 4 !== 0 || notBase64.test(str)) {
    return false
  }

  const firstPaddingChar = str.indexOf('=')
  return (
    firstPaddingChar === -1 ||
    firstPaddingChar === len - 1 ||
    (firstPaddingChar === len - 2 && str[len - 1] === '=')
  )
}

function encodeHTML(string) {
  if (!string) {
    return null
  }

  return Buffer.from(string).toString('base64')
}

function doDecodeHTML(string) {
  if (!isBase64(string)) {
    return string
  }

  const utf8 = Buffer.from(string, 'base64').toString('utf8')

  if (/�/.test(utf8)) {
    return string
  }

  return utf8
}

function decodeHTML(string) {
  if (!string) {
    return null
  }

  // fallback 1
  const stringToDecode = string.startsWith('%3C') ? decodeURI(string) : string

  // fallback 2
  if (string.startsWith('&#60;')) {
    return string.replace(/&#([0-9]{1,4});/gi, (_, num) =>
      String.fromCharCode(parseInt(num))
    )
  }

  return doDecodeHTML(stringToDecode)
}

module.exports = {
  removingTextStyle,
  firstLetterToUpperCase,
  formatWithLocale,
  formatAmount,
  formatAmountSimpleBR,
  formatLocaleToValue,
  encodeHTML,
  decodeHTML,
  htmlEncode,
}
